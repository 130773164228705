/****************************************************************
 * jQuery.paginate.js - Paginate tables
 ***************************************************************/



(function($, ht){
	var initPaginate = function(toInit){
		if($(toInit).data("paginateInit")) return;
		$(toInit).data("paginateInit", true);
		// add watchpoints for pagination
    $("tbody tr:last", toInit).livequery("tbody tr:last", toInit,
				function(){ return; }, // do nothing on addition
				function(){ $(toInit).paginate({page: 0});} // take action when content removed
		);
	};
	$.fn.paginate = function(opts){
		if(!arguments.length){
			opts = {};
		}
		if(!$(this).data("paginateInit")){
			initPaginate(this);
		}
		return this.each(function(){
			var self = this;
			if( $(self).hasClass("footer") || $(self).hasClass("header") ){
				return;
			}
			self.options = $.extend({page: 0, pageSize: 10}, self.options||{}, opts);
			// pep avoid counting quick_view rows
			var numRows = $(self).find("tbody:visible:first tr:not(.quickView,.fake_footer,.noRecords)").length;
			if( numRows == 1){
			 var row = $(self).find("tbody tr");
			//If there are no records,. set the num rows to 0
			 if( $(row).hasClass("noRecords")){
					numRows = 0;
				}
			}

			// function for paging
			var changePage = function(pageNum){
				return function(){
					if((pageNum >= 0) && (numRows > pageNum * self.options.pageSize)){
						$(self).paginate({page: pageNum});
					}
					return false;
				};
			}

			// set up controls
			// pep avoiding tableWidgetOuter if this is a table widget
			var tfoot = self;
			if( !$(self).parent().hasClass("tableWidgetOuter") ){
				tfoot = $("#" + self.id + "_foot").length > 0 ? $("#" + self.id + "_foot") : self;
				if(!$(tfoot).find("tfoot").length){
					$(tfoot).find("thead").after("<tfoot class='ht2-JQueryPaginate ht2-Pagination'><tr class='paginate'><td class='paginate ht2-JQueryPaginatePageStatusAndPagerContainer'><div class='pageStatus ht2-JQueryPaginatePageStatus'></div><ul class='pager ht2-JQueryPaginatePager'></ul></td></tr></tfoot>");
				}else if(!$(tfoot).find("tfoot tr.paginate").length){
					$(tfoot).find("tfoot").append("<tr class='paginate ht2-JQueryPaginate'><td class='paginate ht2-JQueryPaginateTableCell'><div class='pageStatus ht2-JQueryPaginatePageStatus'></div><ul class='pager ht2-JQueryPaginatePager'></ul></td></tr>");
				}
			}

			var colspan	= 0;
			$(self).find("tbody tr:first td").each(function() {
				colspan += ($(this).attr("colspan") != undefined) ? parseInt($(this).attr("colspan")) : 1;
			})

			/*colSpan must be camelCase to work in IE 6, Yaya for IE 6*/
			$(tfoot).find("td.paginate").attr("colSpan", colspan);

			// clear out the footer for pagination controls
			$(tfoot).find("tfoot tr.paginate td.paginate ul").html(" ");

			var sr = self.options.pageSize * self.options.page;
			var er = self.options.pageSize * (self.options.page + 1);
			// hide all rows then show the rows needed for this page
			$(self).find("tbody:not(#searchPending)  tr:not(.fake_footer)").hide();
			$(self).find("tbody:not(#searchPending)  tr:not(.quick_view)").removeClass("ht2-TableFirstVisibleRow ht2-TableLastVisibleRow").slice(sr,er).show();

      const firstVisibleRow = sr;
      const lastVisibleRow = Math.min(er, numRows) - 1;
      $(self).find("tbody:not(#searchPending)  tr:not(.quick_view)").eq(firstVisibleRow).addClass("ht2-TableFirstVisibleRow");
      $(self).find("tbody:not(#searchPending)  tr:not(.quick_view)").eq(lastVisibleRow).addClass("ht2-TableLastVisibleRow");

			if(numRows > self.options.pageSize){
				// prev
				if(self.options.page > 0){
					$(tfoot).find("tfoot  tr.paginate td.paginate ul").append(
						"<li class='ht2-JQueryPaginatePagerPrevious'><a class='paginate previousPage ht2-JQueryPaginatePrev ht2-PaginationPrev' href='#' title='Previous'><span>&lt;</span></a></li> "
					);
					$(tfoot).find("tfoot tr.paginate td.paginate  ul li a:last").click(changePage(self.options.page - 1));
				}
				// add controls for each page
				//TODO: Build markup in loop; append html after loop completes
				for(var i=0; i < numRows / self.options.pageSize; i++){
          const firstClass = i === 0 ? " ht2-JQueryPaginateFirstNumber" : "";
          const lastClass = i === Math.floor(numRows / self.options.pageSize) ? " ht2-JQueryPaginateLastNumber" : "";
          const classes = firstClass + lastClass;
          const pageClasses = `ht2-JQueryPaginatePageNumberAnchorContainer${classes}`;
          const currentPageClasses = `ht2-JQueryPaginateCurrentPageNumber ht2-PaginationCurrentPageNumber${classes}`;
					if(i != self.options.page){

						$(tfoot).find("tfoot  tr.paginate td.paginate  ul").append(" <li class='"+ pageClasses + "'><a class='paginate ht2-JQueryPaginatePageNumberAnchor ht2-PaginationNumber' href='#'>" + (i+1) + "</a></li> ");
						$(tfoot).find("tfoot tr.paginate td.paginate ul li a:last").click(changePage(i));
					}else{
						$(tfoot).find("tfoot tr.paginate td.paginate ul").append("<li class='"+ currentPageClasses + "'>" + (i+1) + "</li> ");
					}
				}

				// next
				if(numRows > (self.options.page + 1) * self.options.pageSize){
					$(tfoot).find("tfoot tr.paginate td.paginate ul").append(
						" <li class='pagerNext ht2-JQueryPaginatePagerNext'><a class='ht2-JQueryPaginateNext ht2-PaginationNext paginate nextPage' href='#' title='Next'><span>&gt;</span></a></li> "
					);
					$(tfoot).find("tfoot tr.paginate td.paginate ul li a:last").click(changePage(self.options.page + 1));
				}
			}

			// populate the page status
			var visibleRows = $(self).find("tbody:visible tr:not(.quickView,.fake_footer):visible").length;
			if( visibleRows == 1 ){
				var row = $(self).find("tbody tr");
				if( $(row).text().indexOf("no orders") != -1){
					visibleRows = 0;
				}
				if($(row).text().indexOf("No results") != -1){
					visibleRows = 0;
					numRows = 0;
				}
			}
			var ofLocalizedString = $("tfoot tr#paginationData").data("oftext") || "of";
			if(numRows > (self.options.page + 1) * self.options.pageSize){
				var viewing = visibleRows * (self.options.page +1);
				var starting = viewing - visibleRows + 1; // so we show "1 - 10" instead of " 0 - 10 "
				$(tfoot).find("tfoot .pageStatus")
				  .html( starting + " - " + viewing +" "+ofLocalizedString+" "+ numRows );
			}else{ // do something different for the last page
				if( numRows == 0 && visibleRows == 0)
					$(tfoot).find("tfoot .pageStatus")
					  .html( numRows - visibleRows + " - " + numRows +" "+ofLocalizedString+" "+ numRows);
				else
					$(tfoot).find("tfoot .pageStatus")
					  .html( numRows - visibleRows + 1 + " - " + numRows +" "+ofLocalizedString+" "+ numRows);
			}
		});
	}
})(jQuery, ht);

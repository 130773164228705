(function (jQuery) {

var $ = jQuery;

//you can find more additional methods at http://jqueryvalidation.org/
jQuery.validator.addMethod("year", function(year, element){
  var valid = /^[1-9][0-9]{3}$/.test(year);

  if(valid == true){
    var current = new Date().getFullYear();
    if( year <= current){
      valid = true;
    }else{
      valid = false;
    }
  }

  return this.optional(element) || valid;
}, "This must be a valid year in the past.");

jQuery.validator.addMethod("age", function(age, element){
  var valid = /^[0-9].*$/.test(age);
  return this.optional(element) || valid;
}, "This must be a valid age.");

/**
 * Check a date,
 * with the following requirements:
 * - mm/dd/yyyy format
 * - works as a JS Date object
 * - after 1890
 * - no 31st day for months 4, 6, 9, 11
 * - no 29th of February except on a leap year
 */

function isDate(value) {
  var re = /^\d{1,2}\/\d{1,2}\/\d{4}$/,
      adata = value.split('/'),
      mm = parseInt(adata[0], 10),
      dd = parseInt(adata[1], 10),
      yyyy = parseInt(adata[2], 10),
      xdata = new Date(yyyy, mm - 1, dd);

  // check that date's format is right
  if (!re.test(value)) {
    return false;
  }

  // check that date works as a JS Date object
  if (!((xdata.getFullYear() === yyyy) && (xdata.getMonth() === mm - 1) && (xdata.getDate() === dd))) {
    return false;
  }

  // check that date is after 1890
  if (yyyy <= 1890) {
    return false;
  }

  // check that there's no improper 31st day of the month
  if ((mm === 4 || mm === 6 || mm === 9 || mm === 11) && dd === 31) {
    return false;
  }

  // check that leap year is accounted for
  if (mm === 2) {
    var isleap = (yyyy % 4 === 0 && (yyyy % 100 !== 0 || yyyy % 400 === 0));
    if (dd > 29 || (dd === 29 && !isleap)) {
      return false;
    }
  }

  // if all tests have passed, return true
  return true;
}

// add a validator method for checking the date as above
jQuery.validator.addMethod(
  "dateMDY",
  function(value, element) {
    var check = isDate(value);
    return this.optional(element) || check;
  },
  "Please enter a valid date"
);

  /**
  Check a date is older than a certain amount
*/
jQuery.validator.addMethod(
    "dateOlderThan",
    function(value, element, params) {
      var date = new Date(value);
      if ( date == 'Invalid Date') {
        return this.optional(element);
      }
      //params is in number of days ONLY
      var numDays = params;
      if ( numDays == '' ) {
        return this.optional(element);
      }

      if ( isNaN(numDays) ) {
        return this.optional(element);
      }

      if ( date.getTime() + (numDays * 86400000) > new Date().getTime() ) {
        return false;
      }

      return true;

    },
    "Date must be at least seven days in the past."
  );


/**
    Validate field is 'Greater Than Or Equal To' a certain length
*/
jQuery.validator.addMethod(
    "numberGELength",
    function(value, element, params) {
      if ( value == "" ) {
        return this.optional(element);
      }
      //params only accepts length ex: 4
      var numChars = params;
      if ( numChars == '' ) {
        return this.optional(element);
      }
      if ( isNaN(numChars) ) {
        return this.optional(element);
      }

      if ( value.length < numChars ) {
        return false;
      }
      return true;

    },
    "Value is not the correct length"
  );

/**
  Validate field is a certain length
*/
jQuery.validator.addMethod(
    "numberLength",
    function(value, element, params) {
      if ( value == "" ) {
        return this.optional(element);
      }
      //params only accepts length ex: 4
      var numChars = params;
      if ( numChars == '' ) {
        return this.optional(element);
      }
      if ( isNaN(numChars) ) {
        return this.optional(element);
      }

      if ( value.length != numChars ) {
        return false;
      }
      return true;

    },
    "Value is not the correct length"
  );



jQuery.validator.addMethod("alphanumeric", function(value, element) {
  return this.optional(element) || /^[\w ]+$/i.test(value);
}, "Letters, numbers or underscores only please");

/* No white space */
jQuery.validator.addMethod("nowhitespace", function(value, element) {
  return this.optional(element) || /^\S+$/i.test(value);
}, "No white space please");

/*
  Social Security Number (SSN) validator
*/
jQuery.validator.addMethod("ssn",
  function(value, element){
    return this.optional(element) || /^\d{3}-?\d{2}-?\d{4}$/.test(value);
  }, "Social security number is not valid must be 9 digits or in XXX-XX-XXXX format."
);

/*
  DOB validator only checks that the date given is before today's date
*/
jQuery.validator.addMethod("dob",
  function(value, element){
    var now = new Date();
    var dob = new Date(value);
    return this.optional(element) || (now.getTime() > dob.getTime());
  }, "Birth date must be in the past"
);

jQuery.validator.addMethod("time", function(value, element) {
    return this.optional(element) || /^([01][0-9])|(2[0123]):([0-5])([0-9])$/.test(value);
  }, "Please enter a valid time, between 00:00 and 23:59"
);

/*validate email in two formats
 * test@healthrio.com
 * tester <test@healthtrio.com> or tester Mctesterson <test@healthtrio.com>
 */
jQuery.validator.addMethod("nameEmail", function (value) {
    if (value == "" || value.match(/^.*\s*\w+@\w+.*$/)) {
      return true;
    } else {
      return false;
    }
  },
  "Please enter a valid email address"
);

jQuery.validator.addMethod("emailList", function(value){
  if(value == "") return true;
  var emailArr = value.split(",");
  var length = emailArr.length;
  var returnValue = false;
  for(var i = 0; i < length; i++){
    if(jQuery.trim(emailArr[i]).match(/^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/i)){
      returnValue = true;
    }else{
      returnValue = false;
    }
  }
  return returnValue;
  },
  "Please enter at least one valid email address"
);

/*validate two fields with case insensitivity and throw error if they do not match
 *for example test@healthtrio.com is the same as TeSt@HeAlThTrIo.CoM
 */
jQuery.validator.addMethod("ignoreCase", function(value,element,params){
  var fieldToMatch = params.fieldToMatch;
  var valueToMatch = jQuery(fieldToMatch).val().toLowerCase();
  var disallowBlank = params.disallowBlank;
  if(value == "" && (disallowBlank == undefined || disallowBlank != true)){
    return true;
  }
  if(value.toLowerCase() == valueToMatch){
    return true;
  }
  return false;
},
  ht.i18n.validate.StandardError
);

jQuery.validator.addMethod("filesize", function(value,element,params){
  //get list of all attachments
  var fileList = element.files;
  //set some defaults that can be overwritten via JSON data
    var bytes = 0;
  var opts = {
    maxsize: 50,
    unit: 'mb'
  };
  opts = jQuery.extend(opts,params);

  //calculate the bytes of the maxsize
  switch(opts.unit.toLowerCase()){
    case 'kb':
      bytes = opts.maxsize * 1024;
      break;
    case 'mb':
      bytes = opts.maxsize * 1048576;
      break;
    case 'gb':
      bytes = opts.maxsize * 1073741824;
      break;
    default:
      bytes = opts.maxsize;
      break;
  }
  //loop through all attachments and throw error if any of them exceed the alotted value
  // must check for existence of fileList, as IE9 and below do not support.
  for(var i=0; fileList != null && i < fileList.length; i++){
    if(fileList[i].size > bytes){
      return false;
    }
  }
  return true;
},
  "The attachment size exceeds the allowable limit"
);

/**
 * matches US phone number format
 *
 * where the area code may not start with 1 and the prefix may not start with 1
 * allows '-' or ' ' as a separator and allows parens around area code
 * some people may want to put a '1' in front of their number
 *
 * 1(212)-999-2345 or
 * 212 999 2344 or
 * 212-999-0983
 *
 * but not
 * 111-123-5434
 * and not
 * 212 123 4567
 */
jQuery.validator.addMethod("phoneUS", function(phone_number, element) {
  phone_number = phone_number.replace(/\s+/g, "");

  return this.optional(element) || phone_number.length > 9 &&
    phone_number.match(/^(\+?1-?)?(\([2-9]([02-9]\d|1[02-9])\)|[2-9]([02-9]\d|1[02-9]))-?([2-9]([02-9]\d|1[02-9]))|([2-8]11)-?\d{4}$/);

}, "Please specify a valid phone number");

/**
 * validator for sections on multi-part forms
 */
jQuery.validator.addMethod("sectionRequired", function(value, element) {
  /*
   * This is just a rough start to section validation for multi-part forms.
   * requires work.
   */

  function reqSection(index) {
    var invalid = currentStep == index && jQuery(element).parents("#child_" + (index + 1)).length;
    return invalid;
  }
  // the required sections
  if (reqSection(0) || reqSection(1) || reqSection(2)) {
    return !this.optional(element);
  }
  return "dependancy-mismatch";
}, jQuery.validator.messages.required);

/**
 * US Zipcode
 * Checks if form input is exactly 5 sequential digits.
 */
jQuery.validator.addMethod("zipcodeUS", function(value, element) {
  return this.optional(element) || /^\d{5}(-\d{4})?$/.test(value);
}, "The specified US ZIP Code is invalid");

/**
 * Check for a valid date range in the format
 * MM/DD/YYYY - MM/DD/YYYY
 * in which
 * - both dates pass the isDate() test above
 * - the second date is after the start date
 * - the spaces around the hyphen are optional
 */

function mdyToObj(str) {
  // converts mm/dd/yyyy formatted string to Date obj
  var dateArr = str.split('/'),
      mm = parseInt(dateArr[0], 10),
      dd = parseInt(dateArr[1], 10),
      yyyy = parseInt(dateArr[2], 10);
  return new Date(yyyy, mm - 1, dd);
}

$.validator.addMethod('dateRange', function (value, element) {

  // strip whitespaces and convert date to array
  var dates = value.replace(/\s/g, '').split('-');

  // check that we have start and end dates to look at

  if (dates.length < 2) {
    return this.optional(element) || false;
  }

  var startDateString = dates[0],
      endDateString = dates[1],
      startDateObj = mdyToObj(startDateString),
      endDateObj = mdyToObj(endDateString),
      result = true;

  // check that both dates pass the isDate() test
  if (!isDate(startDateString) || !isDate(endDateString)) {
    result = false;
  }

  // check that end date is after start date
  if (endDateObj < startDateObj) {
    result = false;
  }

  return this.optional(element) || result;

}, 'Please specify a valid date range');



/**
 * validator for tinymce
 */
 jQuery.validator.addMethod("tinymceContentRequired", function (value, element) {
  //get the content of the tinyMCE editor
  var content = tinyMCE.activeEditor.getContent({format: 'text'});
  //if there's no content throw an error
  if(jQuery.trim(content) == ''){
             return false;
       }
       //if there is content do not error
        return true;    },
    "This field is required."
);

/**
 * Taken from Jquery-validator code.
 * Lets you say "either at least X inputs that match selector Y must be filled,
 * OR they must all be skipped (left blank)."
 *
 * The end result, is that none of these inputs:
 *
 *	<input class="productinfo" name="partnumber">
 *	<input class="productinfo" name="description">
 *	<input class="productinfo" name="color">
 *
 *	...will validate unless either at least two of them are filled,
 *	OR none of them are.
 *
 * partnumber:	{skip_or_fill_minimum: [2,".productinfo",["part number", "description, "color"]]},
 * description: {skip_or_fill_minimum: [2,".productinfo"], ["part number", "description, "color"]]},
 * color:		{skip_or_fill_minimum: [2,".productinfo"], ["part number", "description, "color"]]}
 *
 * options[0]: number of fields that must be filled in the group
 * options[1]: CSS selector that defines the group of conditionally required fields
 * options[2]: Readable names that can be used in the message.
 *
 */

jQuery.validator.addMethod("skip_or_fill_minimum", function(value, element, options) {
  var validatorObject = this;
  var $fields = $(options[1], element.form),
    $fieldsFirst = $fields.eq(0),
    validator = $fieldsFirst.data("valid_skip") ? $fieldsFirst.data("valid_skip") : $.extend({}, validatorObject),
    numberFilled = $fields.filter(function() {
      var fieldToCheck = this;
      return validator.elementValue(fieldToCheck);
    }).length,
    isValid = numberFilled === 0 || numberFilled >= options[0];

  // Store the cloned validator for future validation
  $fieldsFirst.data("valid_skip", validator);

  // If element isn't being validated, run each skip_or_fill_minimum field's validation rules
  if (!$(element).data("being_validated")) {
    $fields.data("being_validated", true);
    $fields.each(function() {
      var fieldToValidate = this;
      validator.element(fieldToValidate);
    });
    $fields.data("being_validated", false);
  }
  return isValid;
}, $.validator.format("At least one of {2} is required."));

/**
 * validates against a regex pattern
 */
jQuery.validator.addMethod( "pattern", function( value, element, param ) {
if ( this.optional( element ) ) {
  return true;
}
if ( typeof param === "string" ) {
  param = new RegExp( "^(?:" + param + ")$" );
}
return param.test( value );
}, "Invalid format." );

/**
* isStartDateYearValidate, isEndDateYearValidate
* CSM1-8114
* The following methods are intended to ensure that 2 date inputs are part of a valid date range.
* For this validator, that is defined as "maximum of 1 year, with no month overlaps", to avoid inaccurate reporting by month.
*/
jQuery.validator.addMethod("isStartDateYearValidate", function(value, element) {
    var endDateId = element.attributes['data-rule-endDateId'].value;
    var endDateEl = jQuery("#"+endDateId);
    if(value != "" && endDateEl[0].value != "") {
        var start = moment(value).startOf('month');
        var end = moment(endDateEl[0].value);
        if (start < end){
          if(end < start.add(12, 'months')){
            return true;
          }else{
            return false;
          }
        } else {
            return false;
        }
    }
    return true;
}, "Invalid date range.");
jQuery.validator.addMethod("isEndDateYearValidate", function(value, element) {
    var startDateId = element.attributes['data-rule-startDateId'].value;
    var startDateEl = jQuery("#"+startDateId);
    if(value != "" && startDateEl[0].value != "") {
        var end = moment(value);
        var start = moment(startDateEl[0].value).startOf('month');
        if (start < end){
          if(end < start.add(12, 'months')){
            return true;
          }else{
            return false;
          }
        } else {
            return false;
        }
    }
    return true;
}, "Invalid date range.");

/**
 * Make sure start date is prior to end date
 */
jQuery.validator.addMethod("isStartDatePrior", function(value, element) {
  var endDateId = element.attributes['data-rule-endDateId'].value;
  var endDateEl = jQuery("#"+endDateId);
  if(value != "" && endDateEl[0].value != "") {
    var start = moment(value);
    var end = moment(endDateEl[0].value);
    if (start > end){
      return false;
    }
  }
  return true;
}, "Invalid date range.");

/**
 * make sure end date is after start date
 */
jQuery.validator.addMethod("isEndDateAfter", function(value, element) {
    var startDateId = element.attributes['data-rule-startDateId'].value;
    var startDateEl = jQuery("#"+startDateId);
    if(value != "" && startDateEl[0].value != "") {
      var end = moment(value);
      var start = moment(startDateEl[0].value);
      if (start > end){
        return false;
      }
    }
    return true;
  }, "Invalid date range.");

jQuery.validator.addMethod("startDateLessThanYear", function(value, element) {
  if(value != "") {
    var end = moment(value);
    var start = moment();
    if(start.add(12, 'months') < end ){
      return false;
    }
  }
  return true;
}, "Cannot be more than a year.");

jQuery.validator.addMethod("isValidDate", function(value, element) {
  var format = element.attributes['format'].value;
  if (format == null || format == "") {
    format = "MM-DD-YYYY";
  }
  return date = moment(value, format).isValid();
}, "Invalid date.");

jQuery.validator.addMethod("isPast", function(value, element) {
  var format = element.attributes['format'].value;
  if (format == null || format == "") {
    format = "MM-DD-YYYY";
  }
  return moment(value, format).diff(moment(),'days') <= 0;
}, "Date must be in the past.");
})(jQuery);

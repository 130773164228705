


if(ht == undefined || ht == null){
	var ht = {};
}
if(ht.i18n == undefined || ht.i18n == null){
	ht.i18n = {};
}

(function(d){
	d.paginate = {
		Prev: 'Prev',
		Next: 'Next'
	};
	d.validate = {
		StandardError: 'Error'
	};
	d.ajax = {
		Loading: 'Loading'	
	};
})(ht.i18n);

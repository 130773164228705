/*
 *  jQuery plugin to hide and show information in a quick view style.
 *  Authored by: Thomas Smith and Pete Peterson
 *  */

(function ($) {
	$.quickview = {};
    $.fn.quickview = function (opts) {
        defaults = {
            /*class of the main tr defined in the ftl*/
            mainTrClass: "mainTr",
            triggerSelector: ".moreInfo",
            /*true hides the border-bottom to make the quick view appear like its all in one container*/
            hideBorder: false,
            /*true highlights the main tr*/
            toggleActive: false,
			/*true will highlight the tr attached to the mainTr*/
			toggleChildActive: false,
            /*where the quick view will be inserted*/
            targetClass: "quickView",
            /*set this to 0 to put contents in the first td, set it to 1 to put it in the second td, etc...*/
            leadingEmptyCellCount: 0,
            /*how many empty cells do you want after your stuff is displayed*/
            trailingEmptyCellCount: 0,
            /*true will toggle the quick view arrow if .quick_view_image_arrow_up is set in the ftl*/
            toggleArrow: false,
            onShow: function () { },
            beforeShow: function () { },
            onClose: function () { }
        };
		
        $(this).data("settings",$.extend(defaults, opts));
        
		clickEvent= function(event){
			var opts = $(this).data("settings");
	    	var parentTr = $(this).parents("." + opts.mainTrClass);
	
	        if (!$(parentTr).next("." + opts.targetClass).length) {
	            // count the columns
	            var cols = $(parentTr).find("td").length;
	            $("<tr class='" + opts.targetClass + " show'/>").insertAfter($(parentTr));
	
	            //create preceding empty table cell(s)
	            if (opts.leadingEmptyCellCount > 0) {
	                for (i = 0; i < opts.leadingEmptyCellCount; i++) {
	                    var emptyTd = $("<td></td>");
	                    $(emptyTd).appendTo($(parentTr).next("tr." + opts.targetClass));
	                }
	            }
	
	            //create table cell and add it to the table row
	            var newTd = $("<td colspan='" + (cols - opts.leadingEmptyCellCount - opts.trailingEmptyCellCount) + "'>" + $(parentTr).find("div." + opts.targetClass).html() + "</td>");
	            $(newTd).appendTo($(parentTr).next("tr." + opts.targetClass));
	
	            //create empty table cell(s) to follow
	            if (opts.trailingEmptyCellCount > 0) {
	                for (i = 0; i < opts.trailingEmptyCellCount; i++) {
	                    var emptyTd = $("<td></td>");
	                    $(emptyTd).insertAfter($(newTd));
	                }
	            }
	
	            //if true hides the bottom-border
	            if (opts.hideBorder) {
	                $(parentTr).find('td').addClass('hideBorder');
	            }
	
	            //if true highlights the main table row
	            if (opts.toggleActive) {
	                $(parentTr).addClass("active");
	            }
	
	            //show new table row with the table cell(s) and settings
	            $(parentTr).next("tr." + opts.targetClass).show();
	
	        } else {
	            //remove table row
	            $(parentTr).next("." + opts.targetClass).remove();
	
	            //bring back the bottom border
	            if (opts.hideBorder) {
	                $(parentTr).find('td').removeClass('hideBorder');
	            }
	
	            //unhighlight the main table row
	            if (opts.toggleActive) {
	                $(parentTr).removeClass("active");
	            }
	        }
	        //if true toggle Arrow up and down
	        if (opts.toggleArrow) {
	            $(parentTr).find(".quick_view_image_arrow_up").toggleClass("quick_view_image_arrow_down");
	        }
	        return false;
	    }
		return this.each(function () {
			$(this).bind("click", this, clickEvent);
		}); 
    };
})(jQuery);  